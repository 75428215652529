<template>
  <header class="header">

    <div class="header__logo">
      <img class="img-fluid" src="@/assets/img/main-logo_white.svg">
    </div>

    <div>
			<h1 class="h2 text-white mb-0">Startende leraren in een professionele teamcultuur</h1>
			<h2 class="h5 text-white mb-0">{{ subtitle }}</h2>
    </div>

    <div class="header__options">
      <a href="#" class="rol-button" v-if="currentRole" v-on:click.prevent="chooseRole()">

        <div class="rol-button__header">Huidige rol</div>

        <div class="rol-button__body">

          <div class="rol-button__icon">
            <span><icon icon="userTag"/></span>
          </div>

          <div class="d-flex flex-column justify-content-center align-items-lg-start pl-1">
            <h6 class="title mb-0">{{ currentRole.name }}</h6>
            <p class="small-text">wijzig</p>
          </div>

        </div>
      </a>
    </div>
  </header>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: 'Home',
  components: {
    Icon
  },
  methods: {
    chooseRole() {
      this.emitter.emit('choose-role', {
        force: true,
        // success() {
        //     _this.$router.push({ path: `/interventies/${theme.slug}` });
        // }
      });
    }
  },
  computed: {
    currentRole() {
      if (this.$store.state.userRole && this.$store.state.roles) {
        return this.$store.getters.getCurrentRole();
      }

      return false;
    },
		subtitle() {
			return 'Voor starters, begeleiders en leidinggevenden';
		}
  }
}
</script>