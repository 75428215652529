<template>
    <div>
        <Header/>

        <div class="main-wrapper">

            <aside>
                <ul class="list-unstyled sidebar-dashboard">
                    <li>
                        <router-link to="/"><span><icon icon="dashboard"/></span>
                            <span>Dashboard</span>
                        </router-link>
                    </li>
                    <li class="seperator"></li>
                    <li>
                        <router-link to="/analyse"><span><icon icon="analyse"/></span>
                            <span>Analyse</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/interventies">
                            <span><icon icon="search"/></span><span>Acties</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/werkplan">
                            <span><icon icon="workplan"/></span><span>Werkplan</span>
                        </router-link>
                    </li>
                    <!-- <li class="seperator"></li>
                    <li>
                        <router-link to="/kennisbank">
                            <span><icon icon="knowledgebase"/></span><span>Kennisbank</span>
                        </router-link>
                    </li> -->
                </ul>
            </aside>

            <main>
                <div class="main__breadcrumbs">
                    <Breadcrumb/>
                </div>
                <div class="main__content">
                    <router-view :key="$route.fullPath"/>
                </div>
                <div class="main__footer">
                    <Footer/>
                </div>
            </main>

            <div class="shape shape--top">
                <div class="shape__inner">
					<svg width="288" height="354" viewBox="0 0 288 354" xmlns="http://www.w3.org/2000/svg"><path d="M282.176 1.197a14.97 14.97 0 0117.268 11.754l.074.39 48.285 273.835c1.417 8.038-3.863 15.707-11.831 17.284l-.312.058-273.836 48.285c-8.038 1.417-15.707-3.863-17.284-11.831l-.058-.312L1.457 96.653c-4.297-24.37 11.77-47.62 35.976-52.295l.736-.136L282.176 1.197zM229.04 84.155L86.714 109.25a9.235 9.235 0 00-7.609 10.393l.05.316 25.096 142.326a9.304 9.304 0 0010.46 7.597l.318-.05 8.542-1.507-24.197-137.228a5.736 5.736 0 014.437-6.602l.216-.043 113.875-20.079a5.736 5.736 0 016.602 4.437l.043.216 20.079 113.875a5.736 5.736 0 01-4.437 6.602l-.216.043-92.588 16.325 3.122 17.705 106.779-18.828a9.304 9.304 0 007.52-10.501l-.042-.265-25.027-142.338a9.234 9.234 0 00-10.697-7.49zM197.71 135.91l-63.993 11.284a3.476 3.476 0 00-2.807 4.094l11.284 63.993.069-.013a3.475 3.475 0 004.025 2.82l63.925-11.272a3.478 3.478 0 002.887-4.038l-11.284-63.993a3.544 3.544 0 00-4.106-2.875z" fill="currentColor" fill-rule="evenodd"/></svg>
                </div>
            </div>

            <div class="shape shape--bottom">
                <div class="shape__inner">
					<svg width="125" height="217" viewBox="0 0 125 217" xmlns="http://www.w3.org/2000/svg"><path d="M-125.437168,1 C-56.7829753,1 5.47028486,28.9267645 50.5603793,74.0154445 C95.6511791,119.10483 123.578616,181.357007 123.578616,250.007941 L123.578616,250.007941 L123.578616,452.815805 C123.578616,465.54813 118.398651,477.094719 110.034848,485.457994 C101.672303,493.820011 90.1265353,499 77.3914427,499 L77.3914427,499 L-637.984216,499 C-706.638958,499 -768.892669,471.074774 -813.982853,425.987436 C-859.073014,380.900122 -887,318.650376 -887,250 C-887,181.352589 -859.0722,119.102043 -813.980897,74.0135858 C-768.89087,28.9264055 -706.637968,1 -637.984216,1 L-637.984216,1 Z" transform="translate(0 -33)" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>
                </div>
            </div>

        </div>
        <!-- <div class="modal-backdrop fade"></div> -->

        <roleModal></roleModal>
    </div>
</template>

<script>
import '@/assets/scss/app.scss';
import '@/assets/scss/print.scss';
import RoleModal from "@/components/RoleModal";
import Header from "./views/layout/Header";
import Footer from "./views/layout/Footer";
import Breadcrumb from "./views/layout/Breadcrumb";
import Icon from "./components/Icon";

export default {
    name: 'Werkomgeving startende leraren',
    components: {Header, Footer, Breadcrumb, Icon, RoleModal},
    beforeCreate() {
        this.$store.commit('initialiseStore');
    },
    mounted() {
        //Fetch by default
        this.$store.dispatch('fetchThemes');
        this.$store.dispatch('fetchRoles');

        // Persistant storage
        this.$store.subscribe((mutation, state) => {
            const store = {
                userRole: state.userRole,
                themes: state.themes,
                aspects: state.aspects,
            };

            window.localStorage.setItem('store', JSON.stringify(store));
        });
    },
    watch: {
        $route() {
            this.$store.commit('CLEAR_BREADCRUMBS');
        }
    }
}
</script>
