import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt';
// import VueGtag from "vue-gtag-next";

const emitter = mitt();

const app = createApp(App);

// Global Properties
app.config.globalProperties.emitter = emitter

// app.use(VueGtag, {
//     property: {
//       id: "UA-156375667-3"
//     }
// });

app.use(store).use(router).mount('#app');