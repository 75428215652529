import axios from 'axios'
import AspectModel from '../models/AspectModel';
import AspectsCollection from '../collections/AspectsCollection';
import store from '@/store'

export default {

    baseUrl() {
        if (window.location.origin.includes('localhost')) {
            return 'http://werkomgeving-startende-leraren.test/wp/wp-json/digitaltool';
        }

        return window.location.origin + '/wp/wp-json/digitaltool';
    },

    async fetchThemes() {
        return await this.request(`/themes/`);
    },
    async fetchTheme(theme_id) {
        let url = `/themes/${theme_id}/`;

        if (typeof store.state.userRole !== 'undefined') {
            url += `?role=${store.state.userRole}`;
        }

        return await this.request(url);
    },
    async fetchRoles() {
        return await this.request(`/roles/`);
    },
    async fetchPage(page_id) {
        return await this.request(`/page/${page_id}/`);
    },
    async fetchIntervention(intervention) {
        return await this.request(`/interventions/${intervention}/`);
    },
    async fetchAspect(aspect_id ) {
        let url = `/aspects/${aspect_id}/`;

        if (typeof store.state.userRole !== 'undefined') {
            url += `?role=${store.state.userRole}`;
        }

        const aspect = await this.request(url);

        return new AspectModel(aspect);
    },
    async fetchAspects(theme) {
        var aspects = await this.request(`/aspects/?role=${store.state.userRole}&theme=${theme}`);
        aspects = aspects.map((aspect) => {
            return new AspectModel(aspect);
        });

        return new AspectsCollection(aspects);
    },
    async fetchKnowledgeBaseItemsByTheme(theme) {
        let url = `/knowledge/?theme=${theme}`;

        if (typeof store.state.userRole !== 'undefined') {
            url += `&role=${store.state.userRole}`;
        }

        const knowledgebaseItems = await this.request(url);

        return knowledgebaseItems;
    },

    request(endpoint) {
        return new Promise((resolve) => {
            axios.get(`${this.baseUrl()}${endpoint}`)
                .then(response => {
                    resolve(response.data.data);
                })
        });
    }

}
