<template>
    <modal ref="modal" title="In welke rol wil je de leeromgeving gebruiken?">
        <p>
          Om een optimale leeromgeving voor startende leraren in een professionele teamcultuur te bewerkstelligen wordt op verschillende niveaus in de organisatie actie ondernomen. Alle acties zijn gericht op hetzelfde doel en zijn goed op elkaar afgestemd, maar vanuit iedere rol, starter, begeleider en leidinggevende worden andere acties uitgevoerd.
        </p>

        <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0" v-for="role in roles" :key="role.id">

                <a class="block block--shadow block--center bg-white" v-on:click="setCurrentRoleForParticipant(role)">

                    <div class="block__icon-circle">
                        <span><icon icon="analyse"/></span>
                    </div>

                    <h3 class="block__title mb-3">{{ role.name }}</h3>
                    <p v-html="role.content"></p>
                    <span class="btn btn-secondary">
                        Kies
                        <span><icon icon="arrow-right"/></span>
                    </span>
                </a>

            </div>
        </div>
    </modal>
</template>

<script>

    import Modal from "@/components/Modal";
    import Icon from "./Icon";

    export default {
        name: 'RoleModal',
        components: {
            Modal,
            Icon,
        },
        data() {
            return {
                successFunc: null
            }
        },
        methods: {
            setCurrentRoleForParticipant(role) {
                const roleId = role.id; 
                
                if(Number.isInteger(roleId) === false){
                    return;
                }
                
                if (typeof this.$gtag !== 'undefined') {
                    this.$gtag.event('select_role', {
                        'event_category' : 'roles',
                        'event_label' : role.name
                    });
                }

                const oldUserRole = this.$store.state.userRole;

                this.$store.commit('SET_USER_ROLE', roleId);

                this.$refs.modal.closeModal();

                if (typeof this.success === 'function') {
                    this.success();
                }

                if (oldUserRole !== null && oldUserRole != roleId) {
                    this.$router.push({path: '/'})
                }
            }
        },

        computed: {
            roles() {
                return this.$store.state.roles;
            }
        },
        mounted() {
            if (typeof this.$store.state.roles === "undefined" || typeof  this.$store.state.userRole === "undefined") {
                this.$store.dispatch('fetchRoles');
            }

            this.emitter.on('choose-role', (e) => {
                if (typeof e.success == 'function') {
                    this.success = e.success;
                }

                if (!this.$store.state.userRole || (typeof e.force === 'boolean' && e.force)) {
                    this.$refs.modal.openModal();
                } else {
                    this.success();
                }
            });

        },
    }
</script>
